import React from "react";
import { Col, Tab, Row, Nav } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";
const Terms = () => {
  if (typeof window !== "undefined") {
    if (!window.location.hash) {
      window.location.hash = "terms";
    }
  }
  //set state from url hash
  const selectedTab =
    typeof window !== "undefined" ? window.location.hash.substring(1) : "terms";

  const handleSelect = (e) => {
    //set state
    //update url
    if (typeof window !== "undefined") {
      window.location.hash = e;
    }
  };

  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: "site-header--menu",
          headerFluid: true,
          footerStyle: "style5",
        }}
      >
        <div className="pt-23 pt-md-25 pt-lg-30 pb-9 pb-md-16 pb-lg-21">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-7 col-lg-8 col-md-11">
                <div className="text-center">
                  <h2 className="font-size-11 mb-8">Terms &amp; Policies</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <Tab.Container defaultActiveKey={selectedTab} onSelect={handleSelect}>
            <Row>
              <Col sm={3}>
                <Nav className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="terms">Terms & Conditions</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="privacy">Privacy Policy</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={9}>
                <Tab.Content>
                  <Tab.Pane eventKey="terms">
                    {" "}
                    <div>
                      <div>
                        <p className="font-size-5 heading-default-color mb-0">
                          Streaka is a SaaS certification management platform
                          that enables organisations (“<b>Clients</b>”) to track
                          and record the certifications held by their suppliers,
                          contractors, and vendors (“<b>Suppliers</b>”). Except
                          as specifically described below in THIRD PARTY
                          SERVICES, the Streaka services and Site
                          (collectively,&nbsp;“
                          <b>Streaka Network</b>”&nbsp;or&nbsp;“
                          <b>Streaka Services</b>”) are operated by Streaka Pty
                          Ltd and its corporate affiliates (collectively, “
                          <b>us</b>”, “<b>we</b>”, “<b>our</b>” or “
                          <b>Streaka</b>”) and are made available through our
                          website at{" "}
                          <a target="blank" href="https://streaka.com">
                            https://streaka.com
                          </a>{" "}
                          or the mobile version (together the “<b>Site</b>”).
                          Your use of the Streaka Services and the Site is
                          governed by these Terms of Service (“
                          <b>Terms of Service</b>
                          ”&nbsp;or&nbsp;“<b>Agreement</b>”), whether or not you
                          are a registered subscriber to the Streaka Services or
                          the Site.
                        </p>
                        <div className="mt-9 mt-lg-11">
                          <h5 className="font-size-7 mb-7">
                            1. Your agreement to Terms of Service
                          </h5>
                          <p className="font-size-5 heading-default-color">
                            By clicking the box below You are signifying that
                            You have read, understand and agree to be bound by
                            the terms of this Agreement whether or not you are a
                            registered subscriber to the Streaka Services or the
                            Site. If You are entering into this Agreement on
                            behalf of a company or other legal entity, You
                            represent that You have the authority to bind such
                            entity and its affiliates to these terms and
                            conditions, in which case the terms "You" or "Your"
                            shall refer to such entity and its affiliates. If
                            You do not have such authority, or if You do not
                            agree with these terms and conditions, You must not
                            accept this Agreement and may not use the Site or
                            the Streaka Services. This Agreement constitutes a
                            binding agreement between You and Streaka. If You do
                            not agree to the terms of this Agreement, You should
                            immediately leave this Site and may not use the
                            Streaka Services.
                          </p>
                          <p className="font-size-5 heading-default-color">
                            Streaka reserves the right, at its sole discretion,
                            to change, modify, add, or delete portions of these
                            Terms of Service at any time with 30 days’ prior
                            notice. If we do this, we will post the changes to
                            these Terms of Service on this page with 30 days’
                            prior notice and will indicate at the top of this
                            page the date these terms were last revised. Your
                            continued use of the Streaka Services or the Site
                            after any such changes constitutes your acceptance
                            of the new Terms of Service.
                          </p>
                          <p className="font-size-5 heading-default-color">
                            If you do not agree to abide by these or any future
                            Terms of Service, do not use or access (or continue
                            to use or access) the Streaka Services or the Site.
                            It is your responsibility to regularly check the
                            Site to determine if there have been changes to
                            these Terms of Service and to review such changes.
                          </p>
                          <p className="font-size-5 heading-default-color">
                            PLEASE READ THIS AGREEMENT CAREFULLY BEFORE USING
                            THE SITE OR PURCHASING AND/OR USING SERVICES OFFERED
                            BY Streaka. THESE TERMS OF SERVICE CONTAIN IMPORTANT
                            INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES
                            AND OBLIGATIONS.
                          </p>
                        </div>
                        <div className="mt-9 mt-lg-11">
                          <h5 className="font-size-7 mb-7">
                            2. Payment and Tax
                          </h5>
                          <p className="font-size-5 heading-default-color">
                            When You subscribe to the Streaka Services and or the
                            Site, we save your credit card number. Unless You
                            terminate your subscription, Your credit card will
                            be automatically charged each time You upgrade Your
                            subscription by adding a product.
                          </p>
                          <p className="font-size-5 heading-default-color">
                            If Your credit card information changes, You must
                            update credit card information in the Account
                            Settings in your Streaka account. If Streaka is not
                            able to charge Your credit card as described above,
                            Your subscription will be terminated as of the end
                            of Your then current subscription term.
                          </p>
                        </div>
                        <div className="mt-9 mt-lg-11">
                          <h5 className="font-size-7 mb-7">3. Definitions</h5>
                          <ul>
                            <li className="heading-default-color">
                              “<b>You</b>”&nbsp;and&nbsp;“<b>Your</b>
                              ”&nbsp;refer to, and includes, You, both
                              individually, as the Employee of the Client or
                              Supplier using or accessing the Streaka Services or
                              Site, as well as the Client or Supplier entity you
                              represent.
                            </li>
                            <li className="heading-default-color">
                              “<b>Employee</b>”&nbsp;refers to any employees or
                              agents of a Client or Supplier accessing the
                              Streaka Services and/or Site on behalf of the
                              Client or Supplier.
                            </li>
                          </ul>
                        </div>
                        <div className="mt-9 mt-lg-11">
                          <h5 className="font-size-7 mb-7">
                            4. Streaka services
                          </h5>
                          <p className="font-size-5 heading-default-color">
                            Our Streaka Services are those Streaka Services that
                            We make available to You as a member of the Streaka
                            Network through Our Site or otherwise.
                          </p>
                          <p className="font-size-5 heading-default-color">
                            Streaka reserves the right to change, modify, suspend
                            or discontinue all or any portion of the Site or any
                            of the Streaka Services, in its sole discretion, with
                            30 days’ prior notice. Streaka may also impose limits
                            on certain features or restrict Your access to parts
                            of or the entire Site in its sole discretion and
                            without notice or liability.
                          </p>
                          <p className="font-size-5 heading-default-color">
                            Streaka may change pricing for the Streaka Services at
                            any time. You will be notified, in writing, at least
                            30 days before any increase in service fees beyond
                            the then current pricing. If you are a Supplier and
                            your subscription changes to a new pricing tier, you
                            will be notified at least 30 days before the price
                            increase takes effect. This Agreement (including the
                            changed pricing) will remain in effect unless You
                            deliver written notice of termination to Streaka
                            within 30 days following such notice.
                          </p>
                        </div>
                        <div className="mt-9 mt-lg-11">
                          <h5 className="font-size-7 mb-7">5. Content</h5>
                          <p className="font-size-5 heading-default-color">
                            You understand that all information, data, text,
                            software, graphics, messages, tags, or other
                            materials (“<b>Content</b>”), whether publicly
                            posted or privately transmitted, are the sole
                            responsibility of the person from whom such Content
                            originated. Streaka does not control the Content
                            posted on the Site, or made available through the
                            Streaka Services and, as such, does not guarantee the
                            accuracy, integrity or quality of such Content. You
                            understand that by using the Streaka Services and/or
                            Site, You may be exposed to Content that You may
                            consider to be offensive, indecent or objectionable.
                            Under no circumstances will Streaka be liable in any
                            way for any Content, including, but not limited to,
                            any errors or omissions in any Content, or any loss
                            or damage of any kind incurred as a result of the
                            use of any Content posted, emailed, transmitted or
                            otherwise made available on the Site.
                          </p>
                          <p className="font-size-5 heading-default-color">
                            You agree that You must evaluate, and bear all risks
                            associated with, the use of any Content, including
                            any reliance on the accuracy, completeness, or
                            usefulness of such Content. In this regard, You
                            acknowledge that You may not rely on any Content
                            created by Streaka or submitted to Streaka.
                          </p>
                          <p className="font-size-5 heading-default-color">
                            You acknowledge, consent and agree that Streaka may
                            access, preserve and disclose Your account
                            information and Content if required to do so by law
                            or in a good faith belief that such access
                            preservation or disclosure is reasonably necessary
                            to:
                          </p>
                          <ol type="a">
                            <li className="heading-default-color">
                              comply with legal process;
                            </li>
                            <li className="heading-default-color">
                              enforce this Agreement;
                            </li>
                            <li className="heading-default-color">
                              respond to claims that any Content violates the
                              rights of third parties;
                            </li>
                            <li className="heading-default-color">
                              respond to Your requests for customer service; or
                            </li>
                            <li className="heading-default-color">
                              protect the rights, property or personal safety of
                              Streaka, its users and the public.
                            </li>
                          </ol>
                        </div>
                        <div className="mt-9 mt-lg-11">
                          <h5 className="font-size-7 mb-7">
                            6. Third party services
                          </h5>
                          <p className="font-size-5 heading-default-color">
                            Streaka may make third party services available to
                            You through the Site (“<b>Third Party Services</b>
                            ”). Use of Third Party Services is governed by the
                            terms provided by the supplier of the Third Party
                            Services. Streaka assumes no liability or
                            responsibility whatsoever for Third Party Services
                            You elect to use. You may not install or use any
                            Third Party Services in a manner that creates any
                            obligations for Streaka. Solely for Your convenience,
                            Streaka may include charges for the Third Party
                            Services as part of Your subscription.
                          </p>
                        </div>
                        <div className="mt-9 mt-lg-11">
                          <h5 className="font-size-7 mb-7">
                            7. Use of the Streaka services
                          </h5>
                          <p className="font-size-5 heading-default-color">
                            You are granted a limited non-exclusive,
                            non-transferable, revocable license to access and
                            use the Streaka Services and Content for which you
                            have a valid subscription strictly in accordance
                            with this Agreement solely for Your legitimate
                            internal business purposes. Only Employees
                            authorised by the subscribing Client or Supplier may
                            access and use the Streaka Services and Site. Any use
                            of the Streaka Services or Content other than as
                            specifically authorised herein, without the prior
                            written permission of Streaka, is strictly
                            prohibited. Unless explicitly stated, nothing in
                            this Agreement shall be construed as conferring any
                            licence to intellectual property rights, whether by
                            estoppel, implication or otherwise. This licence is
                            revocable at any time without notice and with or
                            without cause.
                          </p>
                          <p className="font-size-5 heading-default-color">
                            You may not:
                          </p>
                          <ol type="a">
                            <li className="heading-default-color">
                              copy, print (except for Your own archival purposes
                              in connection with your authorised use of the
                              Streaka Services), republish, display, distribute,
                              transmit, sell, rent, lease, loan or otherwise
                              make available in any form or by any means all or
                              any portion of the Streaka Services or any Content
                              retrieved from it;
                            </li>
                            <li className="heading-default-color">
                              use any Content from the Streaka Services in any
                              manner that may infringe any copyright,
                              intellectual property right, proprietary right, or
                              property right of Streaka or any third parties;
                            </li>
                            <li className="heading-default-color">
                              remove, change or obscure any copyright notice or
                              other proprietary notice or terms of use contained
                              in the Streaka Services or Content;
                            </li>
                            <li className="heading-default-color">
                              make any portion of the Streaka Services available
                              through any timesharing system, service bureau,
                              the Internet or any other technology now existing
                              or developed in the future;
                            </li>
                            <li className="heading-default-color">
                              use the Streaka Services for the purpose of
                              gathering information for or transmitting
                              unsolicited commercial email, telephone calls or
                              facsimile transmissions: or
                            </li>
                            <li className="heading-default-color">
                              use the Streaka Services in a manner that violates
                              any applicable law, rule or regulation.
                            </li>
                          </ol>
                        </div>
                        <div className="mt-9 mt-lg-11">
                          <h5 className="font-size-7 mb-7">
                            8. User content and privacy
                          </h5>
                          <p className="font-size-5 heading-default-color">
                            “<b>User Content</b>”&nbsp;includes all Content that
                            You may upload or submit to Streaka in connection
                            with Your use of the Streaka Services and Site. You,
                            and not Streaka, are entirely responsible for all
                            User Content that You upload, post, email, transmit
                            or otherwise make available through the Streaka
                            Services or the Site. You shall be solely
                            responsible for your own User Content and the
                            consequences of submitting such User Content.
                          </p>
                          <p className="font-size-5 heading-default-color">
                            The terms of Streaka's privacy policy from time to
                            time, available at
                            <a
                              target="blank"
                              href="https://streaka.com/terms-and-privacy#privacy/"
                            >
                              https://streaka.com/terms-and-privacy#privacy/
                            </a>
                            (“<b>Privacy Policy</b>”), are incorporated into
                            these Terms of Service by reference. If You provide
                            or make available any personal information or
                            sensitive information (as those terms are defined in
                            the Privacy Act 1988 (Cth) of a third party to
                            Streaka via the Streaka Services or the Site, You
                            warrant that You have obtained the third party's
                            consent to disclose their information to Streaka for
                            collection, use and transfer in accordance with the
                            terms of the Privacy Policy.
                          </p>
                          <p className="font-size-5 heading-default-color">
                            You hereby grant to Streaka a non-exclusive,
                            transferable, fully-paid, worldwide, perpetual,
                            irrevocable license and right:
                          </p>
                          <ol type="a">
                            <li className="heading-default-color">
                              to use, modify, copy, reproduce, transmit,
                              sub-license, index, model, aggregate, publish,
                              display and distribute Your User Content to
                              provide the Streaka Services to You and all of
                              Streaka's other Client and Supplier customers; and
                            </li>
                            <li className="heading-default-color">
                              to irrevocably anonymise and aggregate Your User
                              Content in order to perform research and
                              development activities and statistical analysis,
                              develop analytic models and to develop and provide
                              other products and services.
                            </li>
                          </ol>
                        </div>
                        <div className="mt-9 mt-lg-11">
                          <h5 className="font-size-7 mb-7">
                            9. Supplier user content
                          </h5>
                          <p className="font-size-5 heading-default-color">
                            Notwithstanding the foregoing, if You are a Supplier
                            and have submitted User Content, You acknowledge and
                            We agree that:
                          </p>
                          <ol type="a">
                            <li className="heading-default-color">
                              Your Supplier User Content will be shared with
                              other authorised Client users appearing on Your “
                              <b>Client List</b>” which are linked to Your
                              account;
                            </li>
                            <li className="heading-default-color">
                              You control which Clients are linked to Your
                              account and can review any time by logging in to
                              Your account; and
                            </li>
                            <li className="heading-default-color">
                              at any time You can add or remove any Client from
                              the Client List by either contacting Us or using
                              the Web interface.
                            </li>
                          </ol>
                          <p className="font-size-5 heading-default-color">
                            Our consortium Client members of the Streaka Network
                            may add Your account to the approved “
                            <b>Supplier List</b>” of such Client. This generates
                            a notification email to Your primary user, stating
                            which Client added You to its approved Supplier List
                            and when. If You do not wish to associate Your
                            account with that Client, You may remove Your
                            account from their approved Supplier List by
                            contacting Us, in which case the Client will be
                            notified that You requested to be removed from such
                            Client’s approved Supplier List.
                          </p>
                          <p className="font-size-5 heading-default-color">
                            We are not responsible for record retention with
                            respect to Your records or any information You
                            provide to Us in connection with Your use of the
                            Streaka Services and Site. Unless, prior to our
                            disposal of any of Your information, You
                            specifically request in writing to have any of Your
                            documentation returned to You, We have the right to
                            dispose of such information without returning such
                            information to You.
                          </p>
                        </div>
                        <div className="mt-9 mt-lg-11">
                          <h5 className="font-size-7 mb-7">
                            10. Streaka proprietary rights
                          </h5>
                          <p className="font-size-5 heading-default-color">
                            Streaka (and its licensors, where applicable) shall
                            own all right, title and interest, including all
                            related intellectual property rights, in and to the
                            Streaka Services (and all of the underlying
                            technology, software and analytics), and Content
                            provided by Streaka (but excluding Your User
                            Content), any models, methods, algorithms,
                            discoveries, inventions, modifications,
                            customisations, enhancements, extensions,
                            derivatives, materials, ideas and other work product
                            that is conceived, originated or prepared in
                            connection with the Streaka Services or the Site or
                            related to this Agreement.
                          </p>
                          <p className="font-size-5 heading-default-color">
                            Streaka and other Streaka graphics, logos, designs,
                            page headers, button icons, scripts and service
                            names are registered trademarks, trademarks or trade
                            dress of Streaka in Australia and/or other countries.
                            Streaka's trademarks and trade dress may not be used,
                            including as part of trademarks and/or as part of
                            domain names, in connection with any product or
                            service in any manner that is likely to cause
                            confusion and may not be copied, imitated, or used,
                            in whole or in part, without the prior written
                            permission of Streaka.
                          </p>
                        </div>
                        <div className="mt-9 mt-lg-11">
                          <h5 className="font-size-7 mb-7">
                            11. Your representations and warranties
                          </h5>
                          <p className="font-size-5 heading-default-color">
                            You represent, warrant, and covenant that:
                          </p>
                          <ul>
                            <li className="heading-default-color">
                              You are entering into this Agreement in Your
                              professional capacity for professional purposes
                              only, as a specialised services provider (if You
                              are a Supplier), or as an operator in the field
                              for which You seek a Supplier (if You are a
                              Client);
                            </li>
                            <li className="heading-default-color">
                              You possess the legal right and ability to enter
                              into this Agreement and to comply with its terms;
                            </li>
                            <li className="heading-default-color">
                              You will use the Streaka Services and Site for
                              lawful purposes only and in accordance with this
                              Agreement and all applicable laws, regulations and
                              policies;
                            </li>
                            <li className="heading-default-color">
                              the information that You provide is truthful and
                              accurate and You have the right to provide such
                              information;
                            </li>
                            <li className="heading-default-color">
                              You agree not to:
                              <ul>
                                <li className="heading-default-color">
                                  use the Streaka Services or Site for fraudulent
                                  or unlawful purposes;
                                </li>
                                <li className="heading-default-color">
                                  attempt to decompile, reverse engineer,
                                  disassemble or hack the Site, or to defeat or
                                  overcome any encryption technology or security
                                  measures implemented by Streaka with respect to
                                  the Site, or any of the Streaka Services and/or
                                  data transmitted, processed or stored by
                                  Streaka;
                                </li>
                                <li className="heading-default-color">
                                  upload, post, email, transmit or otherwise
                                  make available any Content that infringes any
                                  patent, trademark, trade secret, copyright or
                                  other proprietary rights of any party; or
                                </li>
                                <li className="heading-default-color">
                                  upload, post, email, transmit or otherwise
                                  make available any material that contains
                                  software viruses.
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </div>
                        <div className="mt-9 mt-lg-11">
                          <h5 className="font-size-7 mb-7">
                            12. Your confidentiality obligations
                          </h5>
                          <p className="font-size-5 heading-default-color">
                            Our&nbsp;“<b>Confidential Information</b>”&nbsp;is
                            defined as:
                          </p>
                          <ol type="a">
                            <li className="heading-default-color">
                              any and all information to which You are given
                              access or receive regarding any other Supplier or
                              Client; and
                            </li>
                            <li className="heading-default-color">
                              any information, including, but not limited to,
                              business and marketing plans, technology and
                              technical information, product plans and designs,
                              and business processes, disclosed by Us or
                              revealed as a result of Our provision of the
                              Streaka Services, provided, however, that
                              Confidential Information shall not include any
                              information that:
                            </li>
                            <li className="heading-default-color">
                              is or becomes generally known to the public
                              without breach of any obligation owed to Us or any
                              Client or Supplier;
                            </li>
                            <li className="heading-default-color">
                              was known by You prior to its disclosure by Us or
                              any Client or Supplier;
                            </li>
                            <li className="heading-default-color">
                              is received from a third party without breach of
                              any obligation owed to the Us or any Client or
                              Supplier; or
                            </li>
                            <li className="heading-default-color">
                              was independently developed by You without the use
                              of or reference to Our Confidential Information.
                            </li>
                          </ol>
                          <p className="font-size-5 heading-default-color">
                            You shall not use or disclose any of Our
                            Confidential Information for any purpose other than
                            for Your legitimate internal business purposes as
                            necessary to realise the benefits of the Streaka
                            Services offered by Us to You. You will use the same
                            degree of care that You use to protect Your own
                            confidential information of a like kind (but not
                            less than reasonable care). You will limit access to
                            Our Confidential Information to those employees and
                            personnel who need that access for purposes
                            consistent with realising the benefits of the Streaka
                            Services and Site as offered by Us to You.
                          </p>
                        </div>
                        <div className="mt-9 mt-lg-11">
                          <h5 className="font-size-7 mb-7">
                            13. Indemnification for third party claims
                          </h5>
                          <p className="font-size-5 heading-default-color">
                            You agree to defend, indemnify and hold harmless
                            Streaka and Our officers, directors, members,
                            managers, employees, agents, successors and assigns
                            from and against all losses, damages, liabilities,
                            deficiencies, actions, judgments, interest, awards,
                            penalties, fines, costs or expenses of whatever
                            kind, including reasonable legal fees and the cost
                            of enforcing any right to indemnification hereunder
                            and the cost of pursuing any insurance providers (“
                            <b>Loss</b>”) arising out of or resulting from any
                            third party claim, suit, action or proceeding
                            arising out of or resulting from Your use of the
                            Streaka Services or Site, including, but not limited
                            to, any third party claim, suit, action or
                            proceeding arising out of such third party accessing
                            the Content provided by You in connection with Your
                            use of the Streaka Services or Site.
                          </p>
                        </div>
                        <div className="mt-9 mt-lg-11">
                          <h5 className="font-size-7 mb-7">
                            14. Limitation of liability
                          </h5>
                          <p className="font-size-5 heading-default-color">
                            To the maximum extent permitted by law, in no event
                            will Streaka be liable to you for any loss of use,
                            revenue, or profit or loss of data or for any
                            indirect, consequential, exemplary, special or
                            punitive damages whether arising out of breach of
                            contract, tort (including negligence) or otherwise,
                            regardless of whether such damage was foreseeable
                            and whether or not we have been advised of the
                            possibility of such damages. To the maximum extent
                            permitted by law, Streaka’s aggregate liability
                            arising from or relating to this Agreement shall not
                            exceed the amounts actually paid by or due from You
                            in the twelve (12) month period immediately
                            preceding the event giving rise to such liability.
                          </p>
                        </div>
                        <div className="mt-9 mt-lg-11">
                          <h5 className="font-size-7 mb-7">
                            15. No warranties
                          </h5>
                          <p className="font-size-5 heading-default-color">
                            Subject to the following paragraph and to the
                            maximum extent permitted by law, You acknowledge and
                            agree that Streaka makes no warranty of any kind,
                            whether express, implied, statutory or otherwise,
                            and we specifically disclaim all implied warranties,
                            including any implied warranty of merchantability,
                            fitness for a particular purpose, non-infringement,
                            accuracy, correctness, completeness or
                            correspondence with description and any warranty
                            arising from a course of dealing, usage, or trade
                            practice. All services provided by Streaka hereunder
                            are performed and provided on an “<b>as-is</b>” and
                            “<b>as available</b>” basis.
                          </p>
                          <p className="font-size-5 heading-default-color">
                            Where a mandatory term is implied by law or a
                            mandatory consumer guarantee applies to goods or
                            services provided in connection with this Agreement,
                            the mandatory term or mandatory consumer guarantee
                            shall be deemed to be included in this Agreement.
                            However, Streaka's liability for any breach of such
                            guarantee, condition or warranty shall be limited,
                            at Streaka's option, to one or more of the following:
                          </p>
                          <ol type="a">
                            <li className="heading-default-color">
                              if the breach relates to goods, the replacement or
                              repair of the goods, the supply of equivalent
                              goods or the payment of the cost of replacing or
                              repairing the goods or of acquiring equivalent
                              goods; or
                            </li>
                            <li className="heading-default-color">
                              if the breach relates to services, the supplying
                              or cost of having supplied the services again.
                            </li>
                          </ol>
                          <p className="font-size-5 heading-default-color">
                            You acknowledge that access and use of the Streaka
                            Services and/or Site (including the software
                            operating in connection with the Site) may be
                            interfered with by factors outside of Streaka's
                            control.
                          </p>
                        </div>
                        <div className="mt-9 mt-lg-11">
                          <h5 className="font-size-7 mb-7">16. Termination</h5>
                          <p className="font-size-5 heading-default-color">
                            Your right to access and use the Streaka Services and
                            the Site will continue until terminated. If You
                            breach any of these Terms of Service, Streaka may
                            immediately terminate Your access to the Streaka
                            Services and the Site by giving notice to You. You
                            may terminate Your access to the Streaka Services and
                            the Site by written notice to Streaka. Upon
                            termination of Your access to the Streaka Services
                            and the Site for any reason, You shall cease all use
                            of the Streaka Services and the Site.
                          </p>
                          <p className="font-size-5 heading-default-color">
                            Unless otherwise required by law, termination of
                            this Agreement for any reason will not entitle You
                            to any refund of amounts paid in relation to Your
                            use of the Streaka Services or the Site.
                          </p>
                        </div>
                        <div className="mt-9 mt-lg-11">
                          <h5 className="font-size-7 mb-7">
                            17. Governing law
                          </h5>
                          <p className="font-size-5 heading-default-color">
                            This Agreement is governed by the laws of Victoria.
                            The parties submit to the non-exclusive jurisdiction
                            of its courts and the courts of appeal from them.
                            The parties will not object to the exercise of
                            jurisdiction by those courts on any basis.
                          </p>
                          <p className="font-size-5 heading-default-color">
                            Streaka has designed the Streaka Services and the Site
                            for use only within Australia and makes no
                            warranties, guarantees or representations with
                            regard to use by persons accessing, downloading or
                            otherwise using the Streaka Services and/or the Site
                            outside Australia.
                          </p>
                          <p className="font-size-5 heading-default-color">
                            The United Nations Convention on Contracts for the
                            International Sale of Goods is hereby excluded.
                          </p>
                        </div>
                        <div className="mt-9 mt-lg-11">
                          <h5 className="font-size-7 mb-7">
                            18. Miscellaneous terms
                          </h5>
                          <p className="font-size-5 heading-default-color">
                            You and We are independent contractors to each
                            other. This Agreement does not create a partnership,
                            franchise, joint venture, agency, fiduciary or
                            employment relationship between Us.
                          </p>
                          <p className="font-size-5 heading-default-color">
                            You may not assign or delegate Your rights or
                            obligations under this Agreement without the prior
                            written consent of Streaka. Streaka may assign its
                            rights and obligations under this Agreement
                            including without limitation, in connection with the
                            sale of Streaka, whether by merger, asset sale, stock
                            sale or otherwise. Further, Streaka may perform any
                            or all of its obligations through an affiliate or a
                            third party contractor.
                          </p>
                          <p className="font-size-5 heading-default-color">
                            Any provisions of this Agreement which are intended
                            by their nature to survive termination or expiration
                            shall so survive.
                          </p>
                          <p className="font-size-5 heading-default-color">
                            provision of this Agreement that is held to be
                            invalid, illegal or unenforceable will be deleted
                            from this Agreement and the remaining provisions
                            will continue in full force and effect.
                          </p>
                        </div>
                        <div className="mt-9 mt-lg-11">
                          <h5 className="font-size-7 mb-7">
                            19. Supplemental terms
                          </h5>
                          <p className="font-size-5 heading-default-color">
                            The terms of this Agreement are applicable for all
                            users of the Site and Streaka Services. However, in
                            order to properly govern additional terms of Your
                            use of the Site and the Streaka Services, below are
                            links to supplemental terms, which are hereby
                            incorporated into this Agreement by this reference.
                          </p>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="privacy">
                    <p className="font-size-5 heading-default-color">
                      <strong>
                        Introduction
                        <br />
                      </strong>
                      The Privacy Policy of streaka Pty Ltd ACN 121 525 942
                      applies to all personal information collected by streaka.
                      We recognise the importance of protecting and safeguarding
                      your privacy when you deal with us and we are committed to
                      complying with Commonwealth legislation governing the
                      privacy of personal information collected by businesses.
                    </p>

                    <p className="font-size-5 heading-default-color">
                      <strong>
                        Collection of Information
                        <br />
                      </strong>
                      Our primary purpose in collecting personal information is
                      to provide you with a smooth, efficient, safer and
                      customised marketing experience. &nbsp;This allows us to
                      provide services and features that most likely meet your
                      needs, and to customise our services to make your
                      experience safer and easier. We only collect personal
                      information about you that we consider necessary for this
                      purpose and to achieve this goal.
                    </p>

                    <p className="font-size-5 heading-default-color">
                      We will only collect personal information that is
                      necessary for one or more of our functions or activities.
                      Such circumstances include:
                    </p>

                    <ul>
                      <li className="heading-default-color">
                        If you contact streaka, we may keep a record of that
                        correspondence.
                      </li>
                      <li className="heading-default-color">
                        When you establish or access an account login with us.
                      </li>
                      <li className="heading-default-color">
                        When you submit your e-mail address to our web site
                        mailing list.
                      </li>
                    </ul>
                    <p className="font-size-5 heading-default-color">
                      <strong>
                        Use or Disclosure of Personal Information to Others
                        <br />
                      </strong>
                      We may use or disclose personal information for the
                      primary purpose for which the personal information was
                      collected or for purposes related to or ancillary to that
                      primary purpose. This information is only disclosed to
                      persons outside our business in the circumstances set out
                      in this policy or as otherwise notified to you at the time
                      of collection of the information.
                    </p>

                    <p className="font-size-5 heading-default-color">
                      In addition we may use or disclose personal information
                      held about you where you have consented to such use or
                      disclosure or where such use or disclosure is required
                      under or authorised by law, or where we reasonably believe
                      that the use or disclosure is necessary for prevention,
                      investigation, prosecution and punishment of crimes or
                      wrongdoings or the preparation for or conduct of
                      proceedings before any court or tribunal or the
                      implementation of the orders of a court or tribunal by or
                      on behalf of an enforcement body.
                    </p>

                    <p className="font-size-5 heading-default-color">
                      You agree that we may use personal information about you
                      to improve our marketing and promotional efforts, to
                      analyse site usage, to improve our content and product
                      offerings and customise our Site's content, layout, and
                      services.
                    </p>

                    <p className="font-size-5 heading-default-color">
                      <strong>
                        Security and Storage
                        <br />
                      </strong>
                      streaka places great importance on the security of all
                      information associated with our customers, clients and
                      contractors. We have security measures in place to attempt
                      to protect against the loss, misuse and alteration of
                      personal information under our control.
                    </p>

                    <p className="font-size-5 heading-default-color">
                      streaka secures personal information in the same way we
                      safeguard our own sensitive information. We appreciate
                      that no data transmission over the Internet can be
                      guaranteed to be secure and we therefore strive to protect
                      users' personal information by constantly improving the
                      business processes and technology, which we use to protect
                      data.
                    </p>

                    <p className="font-size-5 heading-default-color">
                      <strong>Cookies</strong>
                    </p>

                    <p className="font-size-5 heading-default-color">
                      Cookies are small files that are put onto your device when
                      you visit websites like ours. We use cookies in order to
                      collect information that helps us improve your user
                      experience. You cannot be identified from this
                      information.
                    </p>

                    <p className="font-size-5 heading-default-color">
                      We will not sell your information to any third parties.
                    </p>

                    <p className="font-size-5 heading-default-color">
                      If you do not want cookies, you don’t have to have them!
                      Here is how to&nbsp;disable cookies:
                    </p>

                    <ul>
                      <li className="heading-default-color">
                        <a href="http://support.google.com/chrome/bin/answer.py?hl=en&amp;answer=95647">
                          Chrome
                        </a>
                      </li>
                      <li className="heading-default-color">
                        <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">
                          Firefox
                        </a>
                      </li>
                      <li className="heading-default-color">
                        <a href="http://windows.microsoft.com/en-us/internet-explorer/delete-manage-cookies">
                          Internet Explorer
                        </a>
                      </li>
                      <li className="heading-default-color">
                        <a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac">
                          Safari
                        </a>
                      </li>
                    </ul>

                    <p className="font-size-5 heading-default-color">
                      If you do block cookies, some elements of our site might
                      not work correctly.
                    </p>

                    <p className="font-size-5 heading-default-color">
                      We use cookies to do the following:
                    </p>

                    <ul>
                      <li className="heading-default-color">
                        Offer live chat support
                      </li>
                      <li className="heading-default-color">
                        See which of our pages you visit
                      </li>
                      <li className="heading-default-color">
                        Enable retargeting adverts
                      </li>
                      <li className="heading-default-color">
                        Email related cookies
                      </li>
                      <li className="heading-default-color">
                        Forms related cookies
                      </li>
                      <li className="heading-default-color">
                        Third party analytical cookies
                      </li>
                    </ul>

                    <p className="font-size-5 heading-default-color">
                      By continuing to use our website, you agree to our cookie
                      policy.
                    </p>

                    <p className="font-size-5 heading-default-color">
                      If you would like more information around cookies then you
                      can visit 
                      <a href="https://iccwbo.uk/pages/privacy-policy">
                        International Chamber of Commerce Cookie Guide
                      </a>
                       and 
                      <a href="https://github.com/streaka/streaka/issues/allaboutcookies.org">
                        allaboutcookies.org
                      </a>
                       
                    </p>

                    <p className="font-size-5 heading-default-color">
                      <strong>
                        Access to and Correction of Personal Information
                        <br />
                      </strong>
                      streaka is committed to maintaining accurate, timely,
                      relevant and appropriate information about our customers,
                      clients and web site users. So long as your request for
                      your personal information is in accordance with the
                      National Privacy Principles, then we will give you access
                      to that information through our Privacy Officer:
                    </p>

                    <p className="font-size-5 heading-default-color">
                      Privacy Officer
                    </p>

                    <p className="font-size-5 heading-default-color">
                      streaka Pty Ltd
                    </p>

                    <p className="font-size-5 heading-default-color">
                      Level 34 Santos Place
                      <br />
                      32 Turbot Street
                      <br />
                      BRISBANE
                      <br />
                      QLD 4000
                    </p>

                    <p className="font-size-5 heading-default-color">
                      If we refuse to provide you with access to personal
                      information about you, we will provide you with reasons
                      for the refusal, in accordance with the requirements of
                      the Privacy Act 1988.
                    </p>

                    <p className="font-size-5 heading-default-color">
                      <strong>
                        Complaints
                        <br />
                      </strong>
                      If you have a complaint about our Privacy Policy or the
                      collection, use or safe disposal or destruction of your
                      personal information, your complaint should be directed in
                      the first instance to us at by post.
                    </p>

                    <p className="font-size-5 heading-default-color">
                      Level 34 Santos Place
                      <br />
                      32 Turbot Street
                      <br />
                      BRISBANE
                      <br />
                      QLD 4000
                    </p>

                    <p className="font-size-5 heading-default-color">
                      <strong>
                        Changes to Privacy Policy
                        <br />
                      </strong>
                      streaka may change this Privacy Policy from time to time
                      based on your comments and our need to accurately reflect
                      our data collection and disclosure practices. We will post
                      changes on this Privacy Policy page so that users are
                      always aware of what information is collected, how it is
                      used and the way in which information may be disclosed. As
                      a result, please remember to refer back to this Privacy
                      Policy regularly to review any amendments. All changes to
                      this policy are effective immediately.
                    </p>

                    <p className="font-size-5 heading-default-color">
                      <strong>
                        Contacting us
                        <br />
                      </strong>
                      If you require further information regarding our Privacy
                      Policy, please contact our Privacy Officer at the
                      following address:
                    </p>

                    <p className="font-size-5 heading-default-color">
                      Privacy Officer
                    </p>

                    <p className="font-size-5 heading-default-color">streaka</p>

                    <p className="font-size-5 heading-default-color">
                      Level 34 Santos Place
                      <br />
                      32 Turbot Street
                      <br />
                      BRISBANE
                      <br />
                      QLD 4000
                    </p>

                    <p className="font-size-5 heading-default-color">
                      Should you wish to read more information on privacy
                      legislation or the National Privacy Principles we
                      recommend that you visit the Federal Privacy
                      Commissioner's Web Site.
                    </p>

                    <p className="font-size-5 heading-default-color">
                      <strong>
                        Acceptance
                        <br />
                      </strong>
                      You accept that your use of this site includes your
                      acceptance of this Privacy Policy and the Terms &amp;
                      Conditions of Use
                    </p>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </PageWrapper>
    </>
  );
};
export default Terms;
